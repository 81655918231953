<script>
import HelexFileBase64 from '@/components/global/HelexFileBase64.vue';
export default {
  components: {
    HelexFileBase64
  },
  methods: {
    submitEvent() {
      if (!(this.certificado && this.password)) return 
      const fields = {
        certificado: this.certificado,
        password: this.password
      }
      console.log(fields);
    }
  },
  data() {
    return {
      certificado: null,
      password: null
    }
  }
}
</script>

<template>
  <div>
    <h3>Información del certificado</h3>
    <form @submit.prevent="submitEvent">
      <div class="wrapper-fields">
        <div class="wrapper-input" title="Agregar certificado">
          <label>Agregar certificado</label>
          <HelexFileBase64 id="certificate" required v-model="certificado" />
        </div>
        <div class="wrapper-input" title="Clave del certificado">
          <label for="password">Clave del certificado</label>
          <b-form-input id="password" name="password" required v-model="password"></b-form-input>
        </div>
      </div>
      <vs-button  >Guardar</vs-button>
    </form>
  </div>
</template>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
</style>