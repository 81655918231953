<script>

export default {
  props: {
    value: {},
    name: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      file: null,
      base64: null,
      fileName: null,
      ok: null
    }
  },
  computed: {
    pathAfterSaveInRemote() {
      if (this.isSaveFile) {
        return "https://www.helexiumfiles.com/" + this.pathCleaned + "/" + this.fileName + this.dotExtension;
      } else {
        return ''
      }
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    handleDrop(event) {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },

    validateAndProcessFile(file) {
      // si pasa todas las validaciones
      console.log(file);
      this.fileName = file.name
      this.file = file;
      if (file) {
        const reader = new FileReader();
        const _this = this;
        reader.onload = function (event) {
          _this.base64 = event.target.result.split(',')[1];
          _this.$emit("input", _this.base64)
          _this.ok = true
        };
        reader.onerror = function (error) {
          console.error('Error al leer el archivo:', error);
          _this.ok = false
        };
        reader.readAsDataURL(file);
      }
    },

    onCancel() {
      this.file = null
      this.base64 = null
      this.fileName = null
      this.ok = null
      this.$emit("input", null)
      this.$el.querySelector('input[type="file"]').value = null;

    }
  },


}

</script>

<template>
  <div >
    <input :name="name" @change="handleFileChange" type="file" ref="fileInput" style="display: none" />
    <div class="dropzone" :class="{ 'ok' : ok === true, 'error' : ok === false  }" @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent
      @dragenter.prevent>
      <div class="hidden"></div>
      <div class="content-dropzone">
        <i class="fas fa-cloud-upload-alt fa-1x"></i>
        <span>{{ fileName? fileName : 'Cargar archivo'}}</span>
      </div>
      <div v-if="file" @click.stop="onCancel" class="cancel" title="Quitar certificado">
        <span>&times;</span>
      </div>
      <div v-else></div>
    </div>
  </div>
</template>

<style>
.cancel {
  width: 16px;
  text-align: center;
  box-shadow: inset 0 0 0 1px #1D375F;
  border-radius: 50%;
  transition: .3s;
}
.cancel span {
  color: #1D375F;
}
.cancel:hover {
  background-color: rgb(202, 89, 89);
  box-shadow: inset 0 0 0 1px #fff;
}


.dropzone {
  display: block;
  width: 100%;
  padding: 6px 6px;
  font-size: 14px;
  border-radius: 3px;
  background-color: #0C1A30;
  color: #D5D6D7;
  outline: none;
  border: none;
  box-shadow: 0 0 0 1px #1D375F;
  transition: box-shadow .3s;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.error {
  background-color: rgb(202, 89, 89);
}

.ok {
  background-color: rgb(40, 184, 128);
}

.dropzone:focus {
  box-shadow: 0 0 0 2px #232c7c;
}

body:not(.dark) .dropzone {
  /* background-color: #445B8F;
  box-shadow: 0 0 0 1px #798fc0; */
}

body:not(.dark) .dropzone:focus {
  /* box-shadow: 0 0 0 1px #232c7c; */
}

.dropzone:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.content-dropzone {
  color: #D5D6D7;
  display: flex;
  align-items: center;
  gap: .5rem;
}


</style>